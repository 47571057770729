<template>
    <v-form
        ref="account"
        v-model="isValid"
    >
        <v-card-text>
            <v-text-field
                v-model="userInfo.password_old.value"
                class="purple-input"
                :label="$t('users.profile.tab.password.passwordOld')"
                :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.validPassword]"
                :type="showIcon ? 'text' : 'password'"
                name="input-10-2"
                :error-messages="userInfo.password_old.error"
                @click:append="showIcon = !showIcon"
                @keyup="userInfo.password_old.error = ''"
            />
            <v-text-field
                v-model="userInfo.password.value"
                class="purple-input"
                :label="$t('users.profile.tab.password.password')"
                :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.validPassword, rules.passwordNewDifferent]"
                :type="showIcon ? 'text' : 'password'"
                name="input-10-2"
                :error-messages="userInfo.password.error"
                @click:append="showIcon = !showIcon"
                @keyup="userInfo.password.error = ''"
            />

            <v-text-field
                v-model="userInfo.password_confirmation.value"
                class="purple-input"
                :label="$t('users.profile.tab.password.passwordConfirm')"
                :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, /*rules.minLength8,*/ rules.passwordMatch]"
                :type="showIcon ? 'text' : 'password'"
                name="input-10-2"
                :error-messages="userInfo.password_confirmation.error"
                @click:append="showIcon = !showIcon"
                @keyup="userInfo.password_confirmation.error = ''"
            />
            <div class="error-wrapper">
                <span class="error--text">{{ errorMessage }}</span>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-btn
                :disabled="!isValid && !serverCheck"
                class="mb-4"
                block
                color="primary"
                @click="submit"
            >
                {{ $t('users.profile.tab.password.changePassword') }}
            </v-btn>
        </v-card-actions>
    </v-form>
</template>

<script>
import rules from '@/helpers/rulesValidate';
import axios from '@/plugins/axios';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'BasicInfoForm',
    data() {
        return {
            isValid: false,
            serverCheck: false,
            rules: {
                ...rules,
                passwordMatch: () =>
                    this.userInfo.password.value === this.userInfo.password_confirmation.value ||
                    this.$t('rules.passwordConfirmation'),
                passwordNewDifferent: () =>
                    this.userInfo.password.value !== this.userInfo.password_old.value ||
                    this.$t('rules.passwordNewDifferent')
            },
            userInfo: this.loadUserInfo(),
            errorMessage: '',
            showIcon: false
        };
    },
    computed: {
        ...mapGetters(['getUser'])
    },
    mounted() {
        this.userInfo = this.loadUserInfo();
    },
    methods: {
        ...mapActions(['setUserSimple']),
        loadUserInfo() {
            return {
                password: {
                    value: '',
                    error: ''
                },
                password_confirmation: {
                    value: '',
                    error: ''
                },
                password_old: {
                    value: '',
                    error: ''
                }
            };
        },
        async submit() {
            if (!this.$refs.account.validate()) return false;

            this.$store.dispatch('loading/start');

            try {
                const result = await axios.post('/users/changePassword', {
                    password: this.userInfo.password.value,
                    password_confirmation: this.userInfo.password_confirmation.value,
                    password_old: this.userInfo.password_old.value
                });

                this.$store.dispatch('loading/end');

                if (result.status === 200) {
                    this.$store.dispatch('snackbar/success', this.$t('users.profile.tab.password.passwordChanged'));
                    this.$router.push('/');
                }
            } catch (error) {
                this.$store.dispatch('loading/end');

                if (error.response.status === 422) {
                    console.log(error.response.data);
                    console.log('result');

                    let errors = error.response.data.error;
                    if (errors) {
                        this.userInfo.password_old.error = this.$t(`errors.${errors}`);
                    } else {
                        errors = error.response.data.errors;
                        if (errors && errors.password) {
                            this.userInfo.password.error = this.getServerValidation(errors.password, 'password');
                        } //  "The password must be at least 6 characters."
                        if (errors && errors.password) {
                            // password_confirmation
                            this.userInfo.password_confirmation.error = this.getServerValidation(
                                errors.password,
                                'password_confirmation'
                            ); // "The password confirmation does not match."
                        }
                    }
                }
            }
        },
        getServerValidation(values, field = '') {
            for (let i = 0; i < values.length; i++) {
                const value = values[i];
                if (value) {
                    if (value.indexOf('required') !== -1) {
                        return this.$t('rules.required');
                    } else if (field === 'password' && value.indexOf('password must be at least') !== -1) {
                        return this.$t('rules.password');
                    } else if (
                        field === 'password_confirmation' &&
                        value.indexOf('password confirmation does not match') !== -1
                    ) {
                        return this.$t('rules.passwordConfirmation');
                    }
                }
            }

            return '';
        }
    }
};
</script>

<style scoped></style>
